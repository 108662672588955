import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Flex, Button } from '@ubisend/pulse-components';

const SubscriberScope = ({ content }) => {
  return (
    <Flex start="true">
      <Button
        as={Link}
        variant="secondary"
        icon="user"
        to={`/conversations/${content.id}`}>
        Subscriber {content.id}
      </Button>
    </Flex>
  );
};

SubscriberScope.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

export default SubscriberScope;
