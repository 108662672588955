import React, { useState } from 'react';

import {
  Flex,
  TextInput,
  Panel,
  Label,
  FormGroup,
  Button,
  Explainer,
  useNotification
} from '@ubisend/pulse-components';
import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';

import { updateTeamsSettings } from '../api/index';
import { ExistingSettings } from '../Components/index';
import { useTeamsSettings } from '../hooks/index';

const TeamsSettings = () => {
  const { showSuccess, showError } = useNotification();
  const { settings: initialSettings } = useTeamsSettings();

  const queryClient = useQueryClient();

  const [editing, setEditing] = useState(false);
  const [settings, setSettings] = useState({
    tenantId: initialSettings.tenant_id
  });

  const updateSettings = useMutation(updateTeamsSettings, {
    onSuccess: () => {
      showSuccess('Successfully updated Microsoft Teams Settings.');
      queryClient.invalidateQueries('teams/settings');
      setEditing(false);
    }
  });

  const handleTenantIdChange = e => {
    const id = e.target.value;

    setSettings(settings => ({
      ...settings,
      tenantId: id
    }));
  };

  const formatTenantId = id => {
    if (id.includes('tenantId')) {
      return id.split('tenantId=')[1];
    }

    return false;
  };

  const handleSubmit = () => {
    const tenant_id = formatTenantId(settings.tenantId);

    if (!tenant_id) {
      return showError(
        "That link doesnt contain a tenant ID, check you've copied the right link and try again."
      );
    }

    updateSettings.mutate({
      tenant_id: formatTenantId(settings.tenantId)
    });
  };

  const hasSettings = Boolean(settings.tenantId);
  const showExistingSettings = Boolean(initialSettings.tenant_id && !editing);
  const showUpdateSettings = Boolean(!showExistingSettings || editing);

  const toggleEdit = () => {
    setSettings(settings => ({
      ...settings,
      tenantId: ''
    }));

    setEditing(true);
  };

  const handleCancel = () => {
    setSettings(settings => ({
      ...settings,
      tenantId: initialSettings.tenant_id
    }));

    setEditing(false);
  };

  return (
    <>
      {showExistingSettings && (
        <ExistingSettings
          settings={settings.tenantId}
          toggleEdit={toggleEdit}
        />
      )}
      {showUpdateSettings && (
        <Panel divider header="Link your Microsoft Teams account">
          <FormGroup>
            <Explainer mb>
              Enter the share link from your Team, you can find more information
              on how to find this by visiting this documentation. {' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="/docs/2151317744/2153054534">
                Learn more
              </a>
            </Explainer>
            <Label htmlFor="tenant-id">Team share link</Label>
            <TextInput
              id="tenant-id"
              aria-label="tenant-id"
              placeholder="https://teams.microsoft.com/l/team/&tenantId=123456"
              value={settings.tenantId || ''}
              onChange={handleTenantIdChange}
            />
          </FormGroup>
          <Flex xSpace right>
            {editing && (
              <Button variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
            )}
            <Button
              variant="primary"
              aria-label="submit-teams-settings"
              loading={updateSettings.isLoading}
              type="submit"
              disabled={!hasSettings}
              onClick={handleSubmit}>
              Save
            </Button>
          </Flex>
        </Panel>
      )}
    </>
  );
};

export default TeamsSettings;
