import React, { useEffect, useState } from 'react';

import {
  Flex,
  Span,
  Label,
  Panel,
  Button,
  Checkbox,
  Heading1,
  FormGroup,
  TextInput,
  InputButton,
  InputOverlay
} from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';
import { usePulse, useQuerySearch, useMutation } from '@ubisend/pulse-hooks';

import { acceptInvite } from '../../../api/index';
import { useAuth } from '../../../hooks/index';
import {
  AuthLink,
  AnimatedAuthContainer,
  AuthErrorRedirect,
  AcceptedInviteRedirect,
  AuthenticatedRedirect
} from '../../../Components/index';

const defaultValues = {
  firstName: '',
  lastName: '',
  password: '',
  password_verify: '',
  showPassword: false,
  policyAccepted: false
};

const EmailPasswordAcceptInvite = ({ location }) => {
  const [userData, setUserData] = useState(defaultValues);

  const { authenticated } = useAuth();

  const { platform } = usePulse();

  const mutation = useMutation(acceptInvite);

  const token = useQuerySearch(location, 'token');

  const email = useQuerySearch(location, 'email');

  const handleFirstNameChange = event => {
    const firstName = event.target.value;

    setUserData({ ...userData, firstName });
  };

  const handleLastNameChange = event => {
    const lastName = event.target.value;

    setUserData({ ...userData, lastName });
  };

  const handlePasswordChange = event => {
    const password = event.target.value;

    setUserData({ ...userData, password });
  };

  const handleShowPassword = () => {
    setUserData({ ...userData, showPassword: !userData.showPassword });
  };

  const handleShowPasswordVerify = () => {
    setUserData({
      ...userData,
      showPasswordVerify: !userData.showPasswordVerify
    });
  };
  const handlePasswordVerifyChange = event => {
    const password_verify = event.target.value;

    setUserData({ ...userData, password_verify });
  };

  const handlePolicyChange = () => {
    setUserData({ ...userData, policyAccepted: !userData.policyAccepted });
  };

  const [rulePasswordSame, setRulePasswordSame] = useState(false);

  const [ruleMin14Char, setRuleMin14Char] = useState(false);

  const [ruleContainCapital, setRuleContainCapital] = useState(false);

  const [ruleContainLower, setRuleContainLower] = useState(false);

  const [ruleContainSymbol, setRuleContainSymbol] = useState(false);

  const [rulesMatched, setRulesMatched] = useState(false);

  const valid = Boolean(
    userData.firstName &&
      userData.lastName &&
      userData.policyAccepted &&
      rulesMatched
  );

  const handleSubmit = event => {
    event.preventDefault();

    if (valid && !mutation.isLoading) {
      mutation.mutate({
        email,
        token,
        first_name: userData.firstName,
        last_name: userData.lastName,
        password: userData.password,
        password_verify: userData.password_verify,
        policy_accepted: userData.policyAccepted
      });
    }
  };

  useEffect(() => {
    const resultPasswordSame =
      userData.password === userData.password_verify &&
      userData.password !== '';
    setRulePasswordSame(resultPasswordSame);

    const resultMin14Char = userData.password.length >= 14;
    setRuleMin14Char(resultMin14Char);

    const resultContainCapital = /[A-Z]/.test(userData.password);
    setRuleContainCapital(resultContainCapital);

    const resultContainLower = /[a-z]/.test(userData.password);
    setRuleContainLower(resultContainLower);

    // eslint-disable-next-line no-useless-escape
    const resultContainSymbol = /[!\@\#\$\%\^\&\*\(\)_\+\-\=\[\]\{\}\|\']/.test(
      userData.password
    );
    setRuleContainSymbol(resultContainSymbol);

    // Check if all results are true, then set matchAll to true
    setRulesMatched(
      resultPasswordSame &&
        resultMin14Char &&
        resultContainCapital &&
        resultContainLower &&
        resultContainSymbol
    );
  }, [userData]);

  if (authenticated) {
    return <AuthenticatedRedirect />;
  }

  if (
    !token ||
    !email ||
    (mutation.isError && mutation.error.response.status !== 409)
  ) {
    return <AuthErrorRedirect />;
  }

  if (mutation.isSuccess) {
    return <AcceptedInviteRedirect />;
  }

  return (
    <AnimatedAuthContainer>
      <Heading1 style={{ fontSize: '2rem' }} mb textCenter>
        <span role="img" aria-label="party-popper=icon">
          🎉
        </span>{' '}
        You&apos;ve been invited!
      </Heading1>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="email">Email</Label>
          <Flex relative>
            <TextInput id="email" placeholder="Email" readOnly value={email} />
            <InputOverlay right>
              <Icon
                type="mail"
                size="1.25rem"
                height="1.25rem"
                width="1.25rem"
              />
            </InputOverlay>
          </Flex>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="first_name">First name</Label>
          <Flex relative>
            <TextInput
              id="first_name"
              placeholder="First Name"
              value={userData.firstName}
              onChange={handleFirstNameChange}
            />
            <InputOverlay right>
              <Icon
                type="user"
                size="1.25rem"
                height="1.25rem"
                width="1.25rem"
              />
            </InputOverlay>
          </Flex>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="last_name">Last name</Label>
          <Flex relative>
            <TextInput
              id="last_name"
              placeholder="Last Name"
              value={userData.lastName}
              onChange={handleLastNameChange}
            />
            <InputOverlay right>
              <Icon
                type="user"
                size="1.25rem"
                height="1.25rem"
                width="1.25rem"
              />
            </InputOverlay>
          </Flex>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="password">Password</Label>
          <Flex relative>
            <TextInput
              id="password"
              type={userData.showPassword ? 'text' : 'password'}
              placeholder="New Password"
              value={userData.password}
              onChange={handlePasswordChange}
            />
            <InputButton
              aria-label={`${userData.showPassword ? 'hide' : 'show'} password`}
              onClick={handleShowPassword}>
              <Icon
                size="1.25rem"
                width="1.25rem"
                height="1.25rem"
                type={userData.showPassword ? 'eye' : 'eyeOff'}
              />
            </InputButton>
          </Flex>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="password_verify">Verify Password</Label>
          <Flex relative>
            <TextInput
              id="password_verify"
              type={userData.showPasswordVerify ? 'text' : 'password'}
              placeholder="New Password Again"
              value={userData.password_verify}
              onChange={handlePasswordVerifyChange}
            />
            <InputButton
              aria-label={`${
                userData.showPasswordVerify ? 'hide' : 'show'
              } password`}
              onClick={handleShowPasswordVerify}>
              <Icon
                size="1.25rem"
                width="1.25rem"
                height="1.25rem"
                type={userData.showPasswordVerify ? 'eye' : 'eyeOff'}
              />
            </InputButton>
          </Flex>
        </FormGroup>
        <FormGroup>
          <Panel mt>
            <Flex
              center
              xSpaceSm
              colour={rulePasswordSame ? 'primary' : 'danger'}
              tinyText>
              <Span>
                <Icon
                  tinyText
                  type={rulePasswordSame ? 'check' : 'x'}
                  size={'0.9rem'}
                  height={'0.9rem'}
                />
              </Span>
              <Span>Both passwords must match.</Span>
            </Flex>
            <Flex
              center
              xSpaceSm
              colour={ruleMin14Char ? 'primary' : 'danger'}
              tinyText>
              <Span>
                <Icon
                  tinyText
                  type={ruleMin14Char ? 'check' : 'x'}
                  size={'0.9rem'}
                  height={'0.9rem'}
                />
              </Span>
              <Span>Minimum 14 characters.</Span>
            </Flex>
            <Flex
              center
              xSpaceSm
              colour={ruleContainCapital ? 'primary' : 'danger'}
              tinyText>
              <Span>
                <Icon
                  tinyText
                  type={ruleContainCapital ? 'check' : 'x'}
                  size={'0.9rem'}
                  height={'0.9rem'}
                />
              </Span>
              <Span>At least one uppercase Latin character (A-Z)</Span>
            </Flex>
            <Flex
              center
              xSpaceSm
              colour={ruleContainLower ? 'primary' : 'danger'}
              tinyText>
              <Span>
                <Icon
                  tinyText
                  type={ruleContainLower ? 'check' : 'x'}
                  size={'0.9rem'}
                  height={'0.9rem'}
                />
              </Span>
              <Span>At least one lowercase Latin character (a-z)</Span>
            </Flex>
            <Flex
              center
              xSpaceSm
              colour={ruleContainSymbol ? 'primary' : 'danger'}
              tinyText>
              <Span>
                <Icon
                  tinyText
                  type={ruleContainSymbol ? 'check' : 'x'}
                  size={'0.9rem'}
                  height={'0.9rem'}
                />
              </Span>
              <Span>
                At least one non-alphanumerical character (! @ # $ % ^ & * ( ) _
                + - = [ ] &#123; &#125; | &apos;)
              </Span>
            </Flex>
          </Panel>
        </FormGroup>
        <FormGroup>
          <Checkbox
            checked={userData.policyAccepted}
            onChange={handlePolicyChange}
            label={
              <a target="blank" rel="noreferrer" href={platform.policy_url}>
                {platform.policy_message}
              </a>
            }
            aria-label={platform.policy_message}
          />
        </FormGroup>
        <Flex ySpace center col pt>
          <Button
            variant="primary"
            type="submit"
            fat
            middle
            mt
            disabled={!valid}
            loading={mutation.isLoading}>
            {mutation.isLoading ? 'Submitting' : 'Create Account'}
          </Button>
          <Button variant="inline" as={AuthLink} to="/">
            Go to login
          </Button>
        </Flex>
      </form>
    </AnimatedAuthContainer>
  );
};

export default EmailPasswordAcceptInvite;
