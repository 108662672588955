import React from 'react';
import { styled } from 'twin.macro';
import PropTypes from 'prop-types';

import { Flex, ButtonSelect } from '@ubisend/pulse-components';

const StyledContainer = styled(Flex)`
  padding-left: 1.5rem;
`;

const formatSelectOption = ({ label, onClick }) => ({
  label,
  value: onClick
});

const TabAdminSettings = ({ label, buttons, value, ...props }) => {
  return (
    <StyledContainer>
      <ButtonSelect
        aria-label="admin-settings"
        defaultValue={{ label, value: null }}
        placeholder={'Actions'}
        //   isLoading={!options} TODO
        options={buttons.map(formatSelectOption)}
        onChange={({ value }) => {
          value();
        }}
        isStatic
        isSearchable={false}
        value={null}
        {...props}
      />
    </StyledContainer>
  );
};

TabAdminSettings.propTypes = {
  label: PropTypes.string,
  buttons: PropTypes.array,
  value: PropTypes.string
};

export default TabAdminSettings;
