import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Flex, Button } from '@ubisend/pulse-components';

const AgentScope = ({ content }) => {
  return (
    <Flex start="true">
      <Button
        as={Link}
        variant="secondary"
        icon="user"
        to={`/tickets/agents/${content.id}`}>
        {content.title} ({content.id})
      </Button>
    </Flex>
  );
};

AgentScope.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired
};

export default AgentScope;
