import React, { useEffect, useState } from 'react';

import {
  Flex,
  Span,
  Label,
  Panel,
  Button,
  Heading1,
  FormGroup,
  TextInput,
  InputButton,
  InputOverlay
} from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';
import { useQuerySearch, useMutation } from '@ubisend/pulse-hooks';

import { resetPassword } from '../../../api/index';
import { useAuth } from '../../../hooks/index';
import {
  AuthLink,
  AuthErrorRedirect,
  AnimatedAuthContainer,
  AuthenticatedRedirect,
  ResetPasswordRedirect
} from '../../../Components/index';

const EmailPasswordResetPassword = ({ location }) => {
  const { authenticated } = useAuth();

  const mutation = useMutation(resetPassword);

  const token = useQuerySearch(location, 'token');

  const email = useQuerySearch(location, 'email');

  const [password, setPassword] = useState('');

  const [password_verify, setPasswordVerify] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const [showPasswordVerify, setShowPasswordVerify] = useState(false);

  const [rulePasswordSame, setRulePasswordSame] = useState(false);

  const [ruleMin14Char, setRuleMin14Char] = useState(false);

  const [ruleContainCapital, setRuleContainCapital] = useState(false);

  const [ruleContainLower, setRuleContainLower] = useState(false);

  const [ruleContainSymbol, setRuleContainSymbol] = useState(false);

  const [rulesMatched, setRulesMatched] = useState(false);

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };

  const handlePasswordVerifyChange = event => {
    setPasswordVerify(event.target.value);
  };

  const handleShowPassword = () => {
    setShowPassword(showPassword => !showPassword);
  };

  const handleShowPasswordVerify = () => {
    setShowPasswordVerify(showPasswordVerify => !showPasswordVerify);
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (password && !mutation.isLoading) {
      mutation.mutate({
        email,
        token,
        password,
        password_verify
      });
    }
  };

  useEffect(() => {
    const resultPasswordSame = password === password_verify && password !== '';
    setRulePasswordSame(resultPasswordSame);

    const resultMin14Char = password.length >= 14;
    setRuleMin14Char(resultMin14Char);

    const resultContainCapital = /[A-Z]/.test(password);
    setRuleContainCapital(resultContainCapital);

    const resultContainLower = /[a-z]/.test(password);
    setRuleContainLower(resultContainLower);

    // eslint-disable-next-line no-useless-escape
    const resultContainSymbol = /[!\@\#\$\%\^\&\*\(\)_\+\-\=\[\]\{\}\|\']/.test(
      password
    );
    setRuleContainSymbol(resultContainSymbol);

    // Check if all results are true, then set matchAll to true
    setRulesMatched(
      resultPasswordSame &&
        resultMin14Char &&
        resultContainCapital &&
        resultContainLower &&
        resultContainSymbol
    );
  }, [password_verify, password]);

  if (authenticated) {
    return <AuthenticatedRedirect />;
  }

  if (
    !token ||
    !email ||
    (mutation.isError && mutation.error.response.status !== 409)
  ) {
    return <AuthErrorRedirect />;
  }

  if (mutation.isSuccess) {
    return <ResetPasswordRedirect />;
  }

  return (
    <AnimatedAuthContainer>
      <Heading1 style={{ fontSize: '2rem' }} mb textCenter>
        Reset Password
      </Heading1>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label id="email">Email</Label>
          <Flex relative>
            <TextInput
              htmlFor="email"
              placeholder="Email"
              readOnly
              value={email}
            />
            <InputOverlay right>
              <Icon
                size="1.25rem"
                height="1.25rem"
                width="1.25rem"
                type="mail"
              />
            </InputOverlay>
          </Flex>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="password">Password</Label>
          <Flex relative>
            <TextInput
              id="password"
              type={showPassword ? 'text' : 'password'}
              placeholder="New Password"
              value={password}
              onChange={handlePasswordChange}
            />
            <InputButton
              aria-label={`${showPassword ? 'hide' : 'show'} password`}
              onClick={handleShowPassword}>
              <Icon
                size="1.25rem"
                width="1.25rem"
                height="1.25rem"
                type={showPassword ? 'eye' : 'eyeOff'}
              />
            </InputButton>
          </Flex>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="password_verify">Verify Password</Label>
          <Flex relative>
            <TextInput
              id="password_verify"
              type={showPasswordVerify ? 'text' : 'password'}
              placeholder="New Password Again"
              value={password_verify}
              onChange={handlePasswordVerifyChange}
            />
            <InputButton
              aria-label={`${showPasswordVerify ? 'hide' : 'show'} password`}
              onClick={handleShowPasswordVerify}>
              <Icon
                size="1.25rem"
                width="1.25rem"
                height="1.25rem"
                type={showPasswordVerify ? 'eye' : 'eyeOff'}
              />
            </InputButton>
          </Flex>
        </FormGroup>
        <Panel mt>
          <Flex
            center
            xSpaceSm
            colour={rulePasswordSame ? 'primary' : 'danger'}
            tinyText>
            <Span>
              <Icon
                tinyText
                type={rulePasswordSame ? 'check' : 'x'}
                size={'0.9rem'}
                height={'0.9rem'}
              />
            </Span>
            <Span>Both passwords must match.</Span>
          </Flex>
          <Flex
            center
            xSpaceSm
            colour={ruleMin14Char ? 'primary' : 'danger'}
            tinyText>
            <Span>
              <Icon
                tinyText
                type={ruleMin14Char ? 'check' : 'x'}
                size={'0.9rem'}
                height={'0.9rem'}
              />
            </Span>
            <Span>Minimum 14 characters.</Span>
          </Flex>
          <Flex
            center
            xSpaceSm
            colour={ruleContainCapital ? 'primary' : 'danger'}
            tinyText>
            <Span>
              <Icon
                tinyText
                type={ruleContainCapital ? 'check' : 'x'}
                size={'0.9rem'}
                height={'0.9rem'}
              />
            </Span>
            <Span>At least one uppercase Latin character (A-Z)</Span>
          </Flex>
          <Flex
            center
            xSpaceSm
            colour={ruleContainLower ? 'primary' : 'danger'}
            tinyText>
            <Span>
              <Icon
                tinyText
                type={ruleContainLower ? 'check' : 'x'}
                size={'0.9rem'}
                height={'0.9rem'}
              />
            </Span>
            <Span>At least one lowercase Latin character (a-z)</Span>
          </Flex>
          <Flex
            center
            xSpaceSm
            colour={ruleContainSymbol ? 'primary' : 'danger'}
            tinyText>
            <Span>
              <Icon
                tinyText
                type={ruleContainSymbol ? 'check' : 'x'}
                size={'0.9rem'}
                height={'0.9rem'}
              />
            </Span>
            <Span>
              At least one non-alphanumerical character (! @ # $ % ^ & * ( ) _ +
              - = [ ] &#123; &#125; | &apos;)
            </Span>
          </Flex>
        </Panel>
        <Flex col ySpace center>
          <Button
            mt
            fat
            middle="true"
            variant="primary"
            loading={mutation.isLoading}
            disabled={!rulesMatched}
            type="submit">
            {mutation.isLoading ? 'Submitting' : 'Submit'}
          </Button>
          <Button variant="inline" as={AuthLink} to="/">
            Go to login
          </Button>
        </Flex>
      </form>
    </AnimatedAuthContainer>
  );
};

export default EmailPasswordResetPassword;
