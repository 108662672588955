import React, { useState } from 'react';

import {
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableHeadCell,
  Flex,
  Heading2,
  NoResults,
  Pagination,
  TableCell,
  Button,
  TextInput,
  OrderableTableHeadCell
} from '@ubisend/pulse-components';

import { useMetric } from '../../../hooks/index';
import Scope from '../../Scope';

const orderableTypes = [
  'top-faqs-triggered',
  'average-sentiment-workflow',
  'top-conversations-triggered',
  'sentiment-by-live-chat-agent',
  'negative-sentiment-subscribers'
];

const TableMetric = () => {
  const [search, setSearch] = useState('');

  const { metric, setPage, isLoading, setParams, order } = useMetric();

  const paginateTable = page => setPage(page);

  const TableHeadComponent = props => {
    if (orderableTypes.includes(metric.key)) {
      return <OrderableTableHeadCell {...props} />;
    }

    return <TableHeadCell {...props} />;
  };

  return (
    <Flex col tall>
      <Flex pad between>
        <Heading2>{metric.data.label}</Heading2>
        {metric.data.is_searchable && (
          <Flex xSpaceSm>
            <TextInput
              placeholder="Search..."
              onChange={e => setSearch(e.target.value)}
            />
            <Button
              variant="secondary"
              icon="search"
              onClick={() => setParams({ search })}>
              Search
            </Button>
          </Flex>
        )}
      </Flex>
      <Flex fat grow top>
        {metric.data.data.rows.data.length === 0 && <NoResults />}
        {metric.data.data.rows.data.length > 0 && (
          <Table loading={isLoading} loadingItems={9}>
            <TableHead>
              <TableRow>
                {metric.data.data.headers.map((header, key) => (
                  <TableHeadComponent
                    key={key}
                    row={{ label: header, sort: header }}
                    {...order.props}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {metric.data.data.rows.data.map((row, key) => (
                <TableRow key={key}>
                  {row.map((column, key) => (
                    <TableCell key={key}>
                      <Scope {...column} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Flex>
      {metric.data.data.rows.data.length > 0 && (
        <Pagination
          pagination={metric.data.data.rows}
          handlePageChange={paginateTable}
        />
      )}
    </Flex>
  );
};

export default TableMetric;
