import React from 'react';
import PropTypes from 'prop-types';

import { Span } from '@ubisend/pulse-components';

const SentimentScope = ({ content }) => {
  return <Span uppercase>{content.name}</Span>;
};

SentimentScope.propTypes = {
  content: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
};

export default SentimentScope;
