import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import tw, { styled } from 'twin.macro';

import { StretchPanel, Flex, Button } from '@ubisend/pulse-components';

import { useMetric } from '../../../hooks/index';
import types from '../../../Components/Types/index';
import MetricMenu from './MetricMenu';

const ActionContainer = styled(Flex)`
  ${tw`absolute top-0 right-0 z-10`}
`;

const exportableMetricTypes = ['value'];

// List of metrics that we can export that aren't value type metrics.
const exportableMetricKeys = [
  'top-faqs-triggered',
  'average-sentiment-workflow',
  'top-conversations-triggered',
  'sentiment-by-live-chat-agent',
  'negative-sentiment-subscribers',
  'overall-sentiment-distribution',
  'overall-live-chat-sentiment-distribution'
];

const notCleanExportable = [
  'neutral-sentiment',
  'overall-sentiment',
  'negative-sentiment',
  'positive-sentiment',
  'overall-sentiment-distribution',
  'overall-live-chat-sentiment-distribution'
];

const hasRecordedMetrics = metric => {
  if (exportableMetricKeys.includes(metric.key)) {
    if (metric.data.type === 'table') {
      return metric.data.data.rows.total !== 0;
    }

    return (
      metric.data.data.value !== 0 && metric.data.data.value !== 'NO RESULT'
    );
  }

  if (exportableMetricTypes.includes(metric.data.type)) {
    return (
      metric.data.data.value !== 0 && metric.data.data.value !== 'NO RESULT'
    );
  }

  return false;
};

const DragableMetric = ({ children, ...props }) => {
  const ref = useRef(null);

  const {
    metric,
    editing,
    handleHover,
    handleDelete,
    handleMouseDown,
    start,
    end,
    compare,
    hide
  } = useMetric();

  const [, drop] = useDrop({
    accept: 'METRIC',
    hover(item, monitor) {
      handleHover(ref, monitor.getClientOffset(), item.id, metric.id);
    }
  });
  const [{ handlerId }, drag] = useDrag({
    type: 'METRIC',
    item: () => ({ id: metric.id }),
    canDrag: editing,
    collect: monitor => {
      return {
        handlerId: monitor.getHandlerId()
      };
    }
  });

  drag(ref);
  drop(ref);

  return (
    <StretchPanel
      ref={ref}
      data-handler-id={handlerId}
      layoutId={`metric-${metric.id}`}
      onMouseDown={handleMouseDown}
      style={{
        width: '100%',
        overflowX: 'visible',
        gridColumn: `span ${types[metric.data.type].columns}`,
        gridRow: `auto/ span ${types[metric.data.type].rows}`,
        position: 'relative',
        cursor: editing ? 'move' : 'unset',
        zIndex: hasRecordedMetrics(metric) ? 11 : 10,
        display: hide ? 'none' : 'flex',
        minHeight: '8rem'
      }}
      {...props}>
      <>
        {editing && (
          <ActionContainer padSm>
            <Button
              variant="secondary"
              colour="danger"
              onClick={() => handleDelete(metric.id)}>
              Remove
            </Button>
          </ActionContainer>
        )}
        {!editing && hasRecordedMetrics(metric) && (
          <MetricMenu metric={metric} params={{ start, end, compare }} />
        )}
        <Flex fat col tall middle style={{ opacity: editing ? '0.5' : '1' }}>
          {children}
        </Flex>
      </>
    </StretchPanel>
  );
};

export default DragableMetric;
export { exportableMetricKeys, notCleanExportable };
