import React, { useEffect, useState, useRef } from 'react';

import {
  Box,
  Button,
  Modal,
  Flex,
  LoadingContainer,
  Paragraph,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableActions,
  useNotification,
  OrderableTableRow,
  useOrderableTableReducer,
  usePaginationReducer,
  Pagination
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';
import { useQuery, useQueryClient } from '@ubisend/pulse-hooks';

import { deleteSitemap, createSitemap } from '../api/index';
import { SitemapModal, SitemapUrlExplore } from './index';

const Sitemap = () => {
  const { showSuccess, showError } = useNotification();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sitemapUrl, setSitemapUrl] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSitemap, setSelectedSitemap] = useState(null);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const isMounted = useRef(true);

  const options = [
    { value: 'url', label: 'Single URL' },
    { value: 'xml', label: 'XML Sitemap' },
    { value: 'html', label: 'HTML Sitemap' }
  ];
  const order = useOrderableTableReducer({ id: 'sitemaps' });
  const pagination = usePaginationReducer({ id: 'sitemaps' });

  const showModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);
  const queryClient = useQueryClient();

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [sitemapToDelete, setSitemapToDelete] = useState(null);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Update useQuery to include both refetch conditions
  const { data: sitemaps, isLoading, error } = useQuery(
    ['generative-ai/sitemap', { ...pagination.params, ...order.params }],
    {
      refetchInterval: shouldRefetch ? 5000 : false
    }
  );

  useQuery(['generative-ai/sitemap/job/status/create'], {
    refetchInterval: shouldRefetch ? 5000 : false
  });

  useQuery(['generative-ai/sitemap/job/status/delete'], {
    refetchInterval: shouldRefetch ? 5000 : false
  });

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }

    if (!sitemaps?.data) {
      setShouldRefetch(false);
      return;
    }

    const pending = sitemaps.data.some(sitemap =>
      sitemap.sitemap_urls?.some(
        item => item.status === 'Pending' || item.status === 'Deleting'
      )
    );

    if (isMounted.current) {
      setShouldRefetch(pending);
    }
  }, [sitemaps?.data]);

  const handleConfirm = async () => {
    try {
      const normalizedNewUrl = sitemapUrl
        .toLowerCase()
        .trim()
        .replace(/\/$/, '');

      const isDuplicate = sitemaps?.data?.some(sitemap => {
        const normalizedExistingUrl = sitemap.url
          .toLowerCase()
          .trim()
          .replace(/\/$/, '');
        return normalizedExistingUrl === normalizedNewUrl;
      });

      if (isDuplicate) {
        showError(
          `URL "${sitemapUrl}" is already part of your generative AI knowledge. To replace it, delete the original first.`
        );
        return;
      }

      await createSitemap({
        url: sitemapUrl,
        type: selectedOption.value
      });

      if (isMounted.current) {
        showSuccess(`Successfully created Sitemap.`);
        queryClient.invalidateQueries('generative-ai/sitemap');
        hideModal();
      }
    } catch (error) {
      if (isMounted.current) {
        showError('Failed to create sitemap: ' + error.message);
      }
    }
  };

  const showDeleteModal = sitemap => {
    setSitemapToDelete(sitemap);
    setIsDeleteModalVisible(true);
  };

  const hideDeleteModal = () => {
    setSitemapToDelete(null);
    setIsDeleteModalVisible(false);
  };

  const confirmDelete = async () => {
    try {
      await deleteSitemap(sitemapToDelete.id);
      if (isMounted.current) {
        showSuccess(`Pending for Deletion "${sitemapToDelete.url}".`);
        queryClient.invalidateQueries('generative-ai/sitemap');
        hideDeleteModal();
      }
    } catch (error) {
      if (isMounted.current) {
        showError(`Error deleting sitemap: ${error.message}`);
      }
    }
  };

  useEffect(() => {
    return () => {
      setIsModalVisible(false);
      setIsDeleteModalVisible(false);
    };
  }, []);

  const closeViewSitemapUrl = () => {
    setSelectedSitemap(null);
  };

  if (isLoading) {
    return <LoadingContainer />;
  }

  if (error) {
    return <div>Error loading sitemaps: {error.message}</div>;
  }

  const meta = sitemaps?.meta ?? {};
  // const isProcessing = jobStatusData?.status === 'processing'; // Check if the status is processing

  return (
    <Flex col>
      <PermissionFilter can="view generative ai">
        {sitemaps.data?.length === 0 && (
          <Box border="dashed">
            <Flex col middle center>
              <Paragraph mb secondary>
                No web content has been added yet:
              </Paragraph>
              <Button
                icon="plus"
                variant="primary"
                onClick={showModal}
                disabled={shouldRefetch}>
                Add URLs
              </Button>
              {isModalVisible && (
                <SitemapModal
                  handleClose={hideModal}
                  handleSave={handleConfirm}
                  sitemapUrl={sitemapUrl}
                  setSitemapUrl={setSitemapUrl}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  options={options}
                />
              )}
            </Flex>
          </Box>
        )}
        {sitemaps.data?.length > 0 && (
          <>
            <Flex border xScroll>
              <Table>
                <TableHead>
                  <OrderableTableRow
                    rows={[
                      { label: 'Url', sort: 'url' },
                      { label: 'Type', sort: 'type' },
                      { label: 'Crawled' },
                      null
                    ]}
                    {...order.props}
                  />
                </TableHead>
                <TableBody>
                  {sitemaps.data.map((sitemap, key) => (
                    <TableRow key={key}>
                      <TableCell>{sitemap.url}</TableCell>
                      <TableCell>{sitemap.type}</TableCell>
                      <TableCell>
                        {
                          sitemap.sitemap_urls.filter(
                            item => item.status === 'Success'
                          ).length
                        }
                      </TableCell>
                      <TableActions>
                        <PermissionFilter can="edit generative ai">
                          <Button
                            variant="secondary"
                            icon="eye"
                            colour="primary"
                            onClick={() => setSelectedSitemap(sitemap)}>
                            View
                          </Button>
                          <Button
                            variant="secondary"
                            icon="trash"
                            colour="danger"
                            onClick={() => showDeleteModal(sitemap)}
                            disabled={shouldRefetch}>
                            Delete
                          </Button>
                        </PermissionFilter>
                      </TableActions>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Flex>
            {isDeleteModalVisible && (
              <Modal
                header={<div>Confirm Delete</div>}
                body={
                  <div>
                    Are you sure you want to delete{' '}
                    <strong>{sitemapToDelete?.url}</strong> ?
                  </div>
                }
                cancelText="Cancel"
                handleCancel={hideDeleteModal}
                confirmText="Yes, Delete"
                handleConfirm={confirmDelete}
              />
            )}
            {meta && <Pagination pagination={meta} {...pagination.props} />}
            <Button
              mt
              mlAuto
              icon="plus"
              variant="primary"
              onClick={showModal}
              disabled={shouldRefetch}>
              Add URLs
            </Button>

            {/* Add URL Modal */}
            {isModalVisible && (
              <SitemapModal
                handleClose={hideModal}
                handleSave={handleConfirm}
                sitemapUrl={sitemapUrl}
                setSitemapUrl={setSitemapUrl}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                options={options}
              />
            )}
            <SitemapUrlExplore
              sitemap={selectedSitemap}
              handleHide={closeViewSitemapUrl}
            />
          </>
        )}
      </PermissionFilter>
    </Flex>
  );
};

export default Sitemap;
