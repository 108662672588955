import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  NoScope,
  FaqScope,
  LinkScope,
  UserScope,
  AgentScope,
  ImportScope,
  TicketScope,
  MessageScope,
  SectionScope,
  SessionScope,
  DurationScope,
  FeedbackScope,
  LanguageScope,
  LocationScope,
  StandardScope,
  SentimentScope,
  SmallTalkScope,
  SubscriberScope,
  ConversationScope,
  ConversationStepScope,
  FeedbackCategoryScope
} from './Scopes/index';

const scopes = {
  faq: { name: 'FAQ', Component: FaqScope },
  link: { name: 'Link', Component: LinkScope },
  user: { name: 'User', Component: UserScope },
  agent: { name: 'Agent', Component: AgentScope },
  import: { name: 'Import', Component: ImportScope },
  ticket: { name: 'Ticket', Component: TicketScope },
  message: { name: 'Message', Component: MessageScope },
  section: { name: 'Section', Component: SectionScope },
  session: { name: 'Session', Component: SessionScope },
  standard: { name: 'Value', Component: StandardScope },
  duration: { name: 'Duration', Component: DurationScope },
  feedback: { name: 'Feedback', Component: FeedbackScope },
  language: { name: 'Language', Component: LanguageScope },
  location: { name: 'Location', Component: LocationScope },
  sentiment: { name: 'Sentiment', Component: SentimentScope },
  subscriber: { name: 'Subscriber', Component: SubscriberScope },
  'small-talk': { name: 'Small Talk', Component: SmallTalkScope },
  conversation: { name: 'Conversation', Component: ConversationScope },
  'conversation-step': {
    name: 'Conversation Step',
    Component: ConversationStepScope
  },
  'feedback-category': {
    name: 'Feedback Category',
    Component: FeedbackCategoryScope
  }
};

const Scope = ({ type, ...props }) => {
  const ScopeType = useMemo(() => {
    const scope = scopes[type];

    return scope ? scope.Component : NoScope;
  }, [type]);

  return <ScopeType type={type} {...props} />;
};

Scope.propTypes = {
  type: PropTypes.oneOf(Object.keys(scopes)).isRequired
};

export default Scope;
export { scopes };
