import React from 'react';
import PropTypes from 'prop-types';

import { ButtonSelect as Select } from '@ubisend/pulse-components';
import { useAuth, hasActiveFeatureGate } from '@ubisend/pulse-auth';

import composers from './composers';

const ComposerTypeSelect = ({ value, ...props }) => {
  const auth = useAuth();

  // Check feature gate for templating
  const hasTemplatingFeature = hasActiveFeatureGate('templating')(auth);
  const customComposers = composers.filter(
    composer =>
      Boolean(composer.Input) &&
      (hasTemplatingFeature || composer.id !== 'dynamic')
  );

  const format = composer => ({
    label: composer.name,
    value: composer.id
  });

  return (
    <Select
      options={customComposers.map(format)}
      value={
        value
          ? format(customComposers.find(composer => composer.id === value))
          : null
      }
      {...props}
    />
  );
};

ComposerTypeSelect.propTypes = {
  value: PropTypes.oneOf(composers.map(({ id }) => id))
};

export default ComposerTypeSelect;
